import React from "react";
import { AlbumDetail } from "../components/AlbumDetail";

export default function DisocgraphyDetail({ pageContext }) {
  const { album } = pageContext;
  const props = {
    album,
  };

  return <AlbumDetail {...props} />;
}
