import React from "react";
import styled from "styled-components";

const StyledFormatDetailContainer = styled.div`
  margin-top: 24px;
  h2 {
    font-size: 24px;
  }
  font-size: 24px;
`;

export const FormatDetails = ({ formatDetails, noCart }) =>
  formatDetails && !noCart ? (
    <StyledFormatDetailContainer>
      <h2>Product Detailszzz</h2>
      {formatDetails.map((variant, index) => (
        <p key={index}>{variant.description}</p>
      ))}
    </StyledFormatDetailContainer>
  ) : null;
