import React from "react";
import styled from "styled-components";
import { PurchaseLinks, TrackList } from ".";

const StyledImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
    padding-bottom: ${props => props.theme.layout.gutter / 2}px;
  }
  font-size 20px;
`;

export const ImageColumn = ({ frontCoverArt, tracklist, purchaseLink }) => {
  return (
    <StyledImageContainer className="image-column">
      <img src={frontCoverArt.file.url} />
      {purchaseLink && <PurchaseLinks purchaseLink={purchaseLink} />}
      {tracklist && <TrackList trackList={tracklist} />}
    </StyledImageContainer>
  );
};
