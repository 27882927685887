import React from "react";
import styled from "styled-components";

const StyledTitleContainer = styled.div<any>`
  ${props => `
    font-family: ${props.theme.type.fontFamily};
    .title {
      font-size: 32px;
      margin: 0;
    }
    p.release-date {
      font-size: 20px;
      margin-top: 4px;
      color: #828282;
      margin-bottom: ${props.theme.layout.gutter}px;
    }
`}
`;

const createModifiedReleaseDateText = releaseDate => {
  const longMonthFormat = { month: "long" };
  return `Released in ${new Intl.DateTimeFormat(
    "en-us",
    longMonthFormat
  ).format(new Date(releaseDate))}, ${new Date(releaseDate).getFullYear()}`;
};

export const Title = ({ title, releaseDate }) => {
  const modifiedReleaseDate = createModifiedReleaseDateText(releaseDate);
  return (
    <StyledTitleContainer>
      <h1 className="title">{title}</h1>
      <p className="release-date">{modifiedReleaseDate}</p>
    </StyledTitleContainer>
  );
};
