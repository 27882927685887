import React from "react";
import styled from "styled-components";
import { useViewport } from "../../utils/useViewport";

const StyledTrackList = styled.ul<any>`
  ${props => `
    color: ${props.theme.colors.text};
    list-style: none;
    margin: ${props.addMarginTop ? "64px 0 0 0" : "0"};
    padding: 0;
    b {
      display: block;
      font-size: 20px;
      margin: 0 0 20px 0;
    }
    li {
      font-size: 20px;
      margin: 3px;
    }
  `}
`;

export const TrackList = ({ trackList }) => {
  return trackList ? (
    <StyledTrackList>
      <b>Track List</b>
      {trackList.map((track, index) => (
        <li key={index}>{track}</li>
      ))}
    </StyledTrackList>
  ) : null;
};
