import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledCreditsContainer = styled.div`
  p,
  b {
    font-size: 20px;
  }
`;

export function Credits({ credits }) {
  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <StyledCreditsContainer>
      <b>Credits</b>
      <ReactMarkdown
        renderers={options}
        allowDangerousHtml
        children={credits}
      />
    </StyledCreditsContainer>
  );
}
