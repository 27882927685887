import React from "react";
import styled from "styled-components";
import { useViewport } from "../../utils/useViewport";
import { Description, ImageColumn, Title, TrackList, Credits } from ".";

const StyledDetailGridContainer = styled.div<any>`
  color: ${props => props.theme.colors.text};
  padding: ${props => props.theme.layout.gutter}px;
  font-family: ${props => props.theme.type.fontFamily};
  background-color: transparent;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: ${props => props.theme.layout.gutter}px;

  @media (min-width: 1500px) {
    column-gap: ${props => props.theme.layout.gutter * 2}px;
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .third-container {
    margin-top: 112px;
  }
`;

export function AlbumDetail({ album }) {
  const { currentBreakPoint } = useViewport();
  const threeColumns = currentBreakPoint === "extraLarge";
  const twoColumns =
    currentBreakPoint === "large" || currentBreakPoint === "medium";
  const oneColumn = currentBreakPoint === "small";

  if (threeColumns) {
    return (
      <StyledDetailGridContainer>
        <ImageColumn
          frontCoverArt={album.frontCoverArt}
          tracklist={album.trackList}
          purchaseLink={album.bandcampLink}
        />

        <div className="second-column">
          <Title title={album.title} releaseDate={album.releaseDate} />
          <Description description={album.description.description} />
        </div>

        <div className="third-container">
          <Credits credits={album.credits.credits} />
        </div>
      </StyledDetailGridContainer>
    );
  }

  if (twoColumns) {
    return (
      <StyledDetailGridContainer>
        <ImageColumn
          frontCoverArt={album.frontCoverArt}
          tracklist={album.trackList}
          purchaseLink={album.bandcampLink}
        />

        <div className="second-column">
          <Title title={album.title} releaseDate={album.releaseDate} />
          <Description description={album.description.description} />
          <Credits credits={album.credits.credits} />
        </div>
      </StyledDetailGridContainer>
    );
  }

  if (oneColumn) {
    return (
      <StyledDetailGridContainer>
        <div className="single-column">
          <ImageColumn
            frontCoverArt={album.frontCoverArt}
            tracklist=""
            purchaseLink={album.bandcampLink}
          />

          <Title title={album.title} releaseDate={album.releaseDate} />
          <Description description={album.description.description} />
          <Credits credits={album.credits.credits} />
          <TrackList trackList={album.trackList} />
        </div>
      </StyledDetailGridContainer>
    );
  }
}
