import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledParagraph = styled.div<any>`
  font-size: 20px;
  transition: all 0.15s ease-in-out;
`;

export const Description = ({ description }) => (
  <StyledParagraph>
    <ReactMarkdown>{description}</ReactMarkdown>
  </StyledParagraph>
);
